<template>
   <div class="d-flex flex-column flex-root">
			<div class="page d-flex flex-row flex-column-fluid">
				<div class="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
                    
					<div id="kt_header" class="header">
						<div class="container-xxl d-flex flex-grow-1 flex-stack ">
							<div class="d-flex align-items-center me-5 signupheaderlogo">
								<a class="signupheaderlogo">
									<img alt="Logo" :src="COMPANY_LOGO" class="h-40px h-lg-40px" />                                    
								</a>
							</div>
						</div>
						<!-- <div class="lang" v-if="Nlang != 'en'"><span  @click="changeLangunge()" :class="lang == 'en'?'activeL':''">English</span> 
						 <span  v-if="Nlang != 'ar'" :class="lang != 'en'?'activeL':''" @click="clearLangunge()">{{languageNames.of(Nlang)}}</span>
						 <span v-if="Nlang == 'ar'"  :class="lang != 'en'?'activeL':''" @click="ChangeArabicLangunge()">Arabic</span>
						</div> -->
						<div class="separator"></div>
					</div>
                    
                    <div class="bg-lg">
                        <div id="kt_content_container" class="bg-lg d-flex flex-column-fluid align-items-start container-xxl pt-15 pb-15">
                            <router-view/>
                        </div>
                    </div>
                    
					<div class="footer py-4 d-flex flex-lg-column" id="kt_footer">
						<div class="container-xxl d-flex flex-column flex-md-row align-items-center justify-content-between">
							<div class="text-dark order-2 order-md-1">
								<span class="text-muted fw-bold me-1">{{ new Date().getFullYear() }}©</span>
								<span class="text-gray-800 text-hover-primary">{{COMPANY_Name}}</span>
							</div>
							<ul class="menu menu-gray-600 menu-hover-primary fw-bold order-1">
								<li class="menu-item">
									<a  @click="TremCondition(1)"  class="menu-link px-2">Privacy Policy</a>
								</li>
									<li class="menu-item">
								<a @click="TremCondition(0)"  class="menu-link px-2">Terms & Conditions</a>
								</li>
								<li class="menu-item">
									<a @click="TremCondition(4)"  class="menu-link px-2">About</a>
								</li>
								<li class="menu-item">
									<a @click="TremCondition(5)"  class="menu-link px-2">Support</a>
								</li>
								<li class="menu-item">
									<a @click="TremCondition(3)"    class="menu-link px-2">contact</a>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
	</div> 
</template>

<script>
	/*eslint no-mixed-spaces-and-tabs: ["error", "smart-tabs"]*/
	
import {mapGetters} from 'vuex'
export default {
  name: 'Test',
  setup(){
// 	const languageNames = new Intl.DisplayNames(['en'], {
//   type: 'language'
// })
// return{
// 	languageNames
// }
  },
    data(){
    const COMPANY_LOGO = process.env.VUE_APP_COMPANY_LOGO; 
	const COMPANY_Name = process.env.VUE_APP_COMPANY_Name; 
	
    return {
      COMPANY_LOGO, COMPANY_Name,
	//   lang:'',
    //   Nlang :navigator.language.substring(0,2)=='en'?'ar':navigator.language.substring(0,2)
    }
    },
	computed:{
       ...mapGetters({accoutInfo:"signup/getAccountType"})
	},
	methods:{
//  TremCondition(page_id){
//   let account_type=this.accoutInfo.Account_type
//   this.$router.push('/terms_and_conditions')
//   this.$store.dispatch('signup/FatchTerm',{id:account_type,page_id:page_id})
// }
 TremCondition(page_id){
  let account_type=5
  this.$router.push('/terms_and_conditions')
  this.$store.dispatch('signup/FatchTerm',{id:account_type,page_id:page_id})
},






//  changeLangunge(){
//   localStorage.setItem('lang','en')
//   location.reload();
// },

//  ChangeArabicLangunge (){
//   localStorage.setItem('lang','ar')
//   location.reload();
// },

//   clearLangunge(){	
//   localStorage.removeItem('lang')
//   location.reload()
// },

    // setCookie(cname, cvalue, exdays) {
    //       const d = new Date();
    //       d.setTime(d.getTime() + (exdays*24*60*60*1000));
    //       let expires = "expires="+ d.toUTCString();
    //       document.cookie = 'googtrans' +'=; Path=/;  Domain=' + '.glasshub.ai' +  '; Expires=Thu, 01 Jan 1970 00:00:01 GMT; SameSite=None; Secure' 
    //       document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/"; 
    //     },

    //  googleTranslateElementInit(lang){
    //         this.setCookie('googtrans', `/en/${lang}`,1);
          
    //         // var language = window.navigator.userLanguage || window.navigator.language;
    //         // window.location.replace(`/#googtrans(${lang})`);
    //     }

	},
	mounted(){
		// if(!localStorage.getItem('lang'))
        //   {
        //     this.lang = navigator.language.substring(0,2)
        //     this.googleTranslateElementInit(this.lang)
        //   }else{
        //     this.lang  = localStorage.getItem('lang')  
        //     this.googleTranslateElementInit(this.lang) 
        //   }
	    }
}
</script>
<style scoped>
 .lang{
	width: 150px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
	margin-left: auto;
    margin-right: 50px;
}
.lang>span{
    cursor: pointer;
    font-weight: bold;
  }
  .lang .activeL{
    color: #5241a1;
  
}
</style>